import IconChevron from "../icons/IconChevron.tsx";

interface VehicleCardPerksProps {
    vehicleGroupPerks?: string[]
}

const VehicleCardPerks = (props: VehicleCardPerksProps) => {
    const {
        vehicleGroupPerks
    } = props;

    return (
        vehicleGroupPerks?.map((perk) => (
            <>
                <span key={perk}>
            <IconChevron
                width="12"
                fill="#4A79E2"
                chevronOrientation="right"
            />
                    {perk}
            </span>
            </>
        ))
    );
};

export default VehicleCardPerks;