import BookingType from "../types/BookingType.type.ts";
import rootExtractor from "../../../../utils/dataset/rootExtractor.ts";
import {contactInformationFormInitialState} from "../features/contact/constants/contactInformationFormInitialState.ts";
import {paymentTransferFormInitialState} from "../features/payment/constants/paymentTransferFormInitialState.ts";
import {privacyTransferFormInitialState} from "../features/privacy/constants/privacyTransferFormInitialState.ts";
import {
    flightTransferFormInitialState,
    returnFlightTransferFormInitialState
} from "../features/flight/constants/flightTransferFormInitialState.ts";

export const bookingSliceInitialState: BookingType = {
    startLocation: '',
    destinationLocation: '',
    isDepartureAirport: false,
    isDestinationAirport: false,
    numberOfPeople: 0,
    vehicleGroup: 0,
    operatorId: null,
    country: '',
    countryCode: '',
    isoCode: '',
    comment: '',
    privacyPolicy: false,
    reviewMailConsent: true,
    newsletterConsent: false,
    locale: rootExtractor('locale'),
    dataSignature: '',
    displayFormErrors: false,
    submitTimestamp: '',
    contactInformationForm: contactInformationFormInitialState,
    paymentTransferForm: paymentTransferFormInitialState,
    privacyTransferForm: privacyTransferFormInitialState,
    flightTransferForm: flightTransferFormInitialState,
    returnFlightTransferForm: returnFlightTransferFormInitialState,
    amenities: {},
    isBooked: null
};