import VehicleCards from "./VehicleCards.tsx";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {useEffect} from "react";
import PricesMayVaryNotification from "./PricesMayVaryNotification.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {
    maxConsequentFailedMozioFetchCount
} from "../features/mozioResults/constants/maxConsequentFailedMozioFetchCount.ts";
import ProgressBar from "../../../components/generic/loaders/ProgressBar.tsx";
import useFormPlaceValidator from "../hooks/useFormPlaceValidator.ts";
import PoolMozioSearchResultsResponseType from "../../../api/types/PoolMozioSearchResultsResponse.type.ts";
import IncompleteSearchFormDataDialog
    from "../features/incompleteDialog/components/IncompleteSearchFormDataDialog.tsx";
import FilterCategorySection from "../features/filterResults/components/FilterCategorySection.tsx";
import {setCategoryPrice} from "../reducers/functions/searchResultsSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import VehicleTypeCardLoader from "../../../components/generic/loaders/VehicleTypeCardLoader.tsx";
import useSearchTripsEvent from "../../../features/events/hooks/useSearchTripsEvent.ts";

interface SearchResultsProps {
    data: any,
    isLoading: boolean,
    error: FetchBaseQueryError | SerializedError | undefined,
    mozioVehiclesData: PoolMozioSearchResultsResponseType
    mozioIsUninitialized: boolean
    mozioIsLoading: boolean
    unsuccessfulMozioFetchCount: number
    originalArgs: any
    dialogRef: HTMLDialogElement
    onIncompleteSearchFormData: () => void
}

function SearchResults(props: SearchResultsProps) {
    const dispatch = useAppDispatch();
    const {
        data,
        mozioVehiclesData,
        mozioIsLoading,
        unsuccessfulMozioFetchCount,
        originalArgs,
        dialogRef,
        onIncompleteSearchFormData
    } = props;
    const {
        successfulMozioFetchCount,
        categoryPrices
    } = useAppSelector((state) => (state.searchResults));

    const event = useSearchTripsEvent(data?.data?.gbt?.vehicleGroups);

    const {lastSearchInvalidDeparture, lastSearchInvalidDestination} = useFormPlaceValidator()
    const isComplete = !(lastSearchInvalidDestination || lastSearchInvalidDeparture)

    useEffect(() => {
        let currentCategoryPrices = {};
        data?.data?.gbt?.vehicleGroups.forEach((vehicleType) => {
            if (!currentCategoryPrices[vehicleType.vehicleCategoryId] || Number(currentCategoryPrices[vehicleType.vehicleCategoryId]) > Number(vehicleType.price)) {
                currentCategoryPrices = ({
                    ...currentCategoryPrices,
                    [vehicleType.vehicleCategoryId]: vehicleType.price
                })
                dispatch(setCategoryPrice(currentCategoryPrices));
            }
        });
    }, [data])

    useEffect(() => {
        event()
    }, []);


    const isMozioLoading = ((successfulMozioFetchCount < 10 && unsuccessfulMozioFetchCount < maxConsequentFailedMozioFetchCount && mozioVehiclesData?.data?.moreComing) || mozioIsLoading)

    return (
        <div className="gb--vehicle__results">
            {data?.data?.integrations?.mozio?.moreComing && (
                <ProgressBar displaySign percentage={successfulMozioFetchCount} convertToPercentage/>
            )}
            <FilterCategorySection isLoading={data?.data?.integrations?.mozio?.moreComing}/>
            {!isComplete && (
                <PricesMayVaryNotification/>
            )}
            {data?.data?.integrations?.mozio?.moreComing && (
                <VehicleTypeCardLoader />
            )}
            {data?.data?.gbt && (
                <VehicleCards
                    isReturn={data.data.isRoundTrip}
                    isAirport={data?.data?.pickupInfo.isAirport || data?.data?.dropOffInfo.isAirport}
                    duration={data?.data?.duration}
                    vehicles={data?.data?.gbt?.vehicleGroups}
                    onIncompleteSearchFormData={onIncompleteSearchFormData}
                    categoryPrices={categoryPrices}
                />
            )}
            <IncompleteSearchFormDataDialog
                dialogRef={dialogRef}
                originalArgs={originalArgs}
            />
        </div>
    )
}

export default SearchResults