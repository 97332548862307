import MobileOverlay from "../../../components/MobileOverlay.tsx";
import MobilePlaceInputButton from "../../../../../layout/form/features/stations/components/MobilePlaceInputButton.tsx";
import DateTimeField from "../../../../../layout/form/features/dates/components/DateTimeField.tsx";
import useDismissAllOverlays from "../../../hooks/useDismissAllOverlays.ts";
import useConfirmUpdatedFormData from "../hooks/useConfirmUpdatedFormData.ts";
import {useDispatch} from "react-redux";
import {initialState, setVehicleType} from "../../../../../reducers/vehicleTypeSlice.ts";
import useFormPlaceValidator from "../../../../../pages/searchResults/hooks/useFormPlaceValidator.ts";
import Translation from "../../../../translation/components/Translation.tsx";
import useCachedData from "../../../../../pages/searchResults/hooks/useCachedData.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import Button from "../../../../../components/generic/Button.tsx";

function IncompleteSearchFormDataOverlay() {
    const {
        departureDate
    } = useAppSelector((state) => state?.form)
    const {
        isIntegration
    } = useAppSelector((state) => state?.vehicleType);

    const inventory = isIntegration ? 'integration' : 'native'
    const {invalidDeparture, invalidDestination, areSame} = useFormPlaceValidator()

    const invalidAddresses = invalidDeparture || invalidDestination || areSame
    const invalidForm = invalidDeparture || invalidDestination || !departureDate

    const {
        fetchedGbtOriginalArgs: originalArgs
    } = useCachedData()

    const dispatch = useDispatch()
    const dismissAllOverlays = useDismissAllOverlays()

    const goBack = () => {
        dismissAllOverlays()
        dispatch(setVehicleType(initialState))
    }

    const {confirmNewData} = useConfirmUpdatedFormData(dismissAllOverlays, originalArgs)

    return (
        <MobileOverlay
            wrapperClassName="incomplete-search-form-overlay"
            title={(
                <Translation
                    translationKey={`booking.search.incompleteAddress.dialog.${inventory}.title`}
                />
            )}
            isActionSheet
            backClick={goBack}
        >
            <div className="incomplete-search-form-overlay--content">
                <hr/>
                <p>
                    <Translation
                        translationKey={`booking.search.incompleteAddress.dialog.${inventory}.description`}
                    />
                </p>
                <MobilePlaceInputButton
                    direction="departure"
                    showIsValid
                    isValid={!invalidDeparture && !areSame}
                />
                <MobilePlaceInputButton
                    direction="destination"
                    showIsValid
                    isValid={!invalidDestination && !areSame}
                />
                <div className={"search__select search__select--datepicker gb--row gb--row-middle"}>
                    <DateTimeField isValid={!!departureDate} showIsValid={true}/>
                </div>
                <Button
                    onClick={() => {
                        if (!invalidAddresses) {
                            confirmNewData()
                        }
                    }}
                    type="button"
                    width="full"
                    size="large"
                    className='blue-darker'
                    appearance={invalidForm ? "inactive" : undefined}
                    additionalClasses="incomplete-search-form-overlay--content__button-confirm"
                >
                    <Translation
                        translationKey="booking.search.incompleteAddress.confirm"
                    />
                </Button>
            </div>
        </MobileOverlay>
    )
}

export default IncompleteSearchFormDataOverlay